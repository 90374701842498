// ページネーションの挙動
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        $(".pagination a").click(function(){
            if($(this).hasClass("is-disabled")) return;
            if($(this).hasClass("is-current")) return;
            $product_search_page = $form.find("#product_search_page");
            page = $(this).data("page");
            $product_search_page.val(page);
            $form.find("#product_search_submit").click();
        });
    });
});
