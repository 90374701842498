// チェックボックスで検索条件のon,offを表現している所用
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        $(".submitable_element").click(function(){
            $form.find("#product_search_submit").click();
        });
    });
});
