require('./../../public/javascripts/modaal')

export function init_cart() {
  //カート内全削除
  $(document).on('click', '.js-cart-delete', function () {
    operate_cart_items('delete_cart');
  });

  $('input.js-not_write_name, input.js-accept_write_name').click(function () {
    $('#js-no_selected_product_option').hide();
    $('#js-selected_product_option').show();
    if ($(this).val() == 'not_write_name') {
      $('#js-product_option').hide();
    } else {
      $('#js-product_option').show();
    }
  });

  //再計算
  var timer = false;
  $(document).on('input', '.cart_amount_field', function () {
    var target = $(this);

    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      var amount_id = target.attr('data-amount-field-id');
      var amount = $(`input[name='${amount_id}']`).val();
      if(amount < 1){
        alert('数量には1以上を指定してください');
        return;
      }

      var catalog_item_cd = target.attr('data-catalog-item-cd');
      var cart_item_data = {
        catalog_item_cd: catalog_item_cd,
        amount: amount,
      }
      operate_cart_items('update_cart_detail', cart_item_data);
    }, 400);
  });

  // カートに戻す
  $(document).on('click', '.js-cart-pending-item-to-move-cart', function () {

    var pending_item_id = $(this).attr('data-cart-pending-item-id');
    var pending_item_data = {
      id: pending_item_id,
    };

    operate_cart_items('move_cart_pending_item', pending_item_data);
  });

  // あとで買うに追加
  $(document).on('click', '.js-cart-detail-move-to-cart-pending-item', function () {
    var cart_detail_id = $(this).attr('data-cart-detail-id');
    var cart_item_data = {
      id: cart_detail_id,
    };
    operate_cart_items('move_cart_detail', cart_item_data);
  });

  // あとで買う商品削除モーダルを開く
  $(document).on('click', '.js-open-cart-pending-item-delete-modal', function () {
    window.cart_pending_item_id = $(this).attr('data-cart-pending-item-id');
  });

  // 商品削除モーダルを開く
  $(document).on('click', '.js-open-cart-detail-delete-modal', function () {
    window.cart_detail_id = $(this).attr('data-cart-detail-id');
  });

  // カート内商品削除
  $(document).on('click', '.js-cart-detail-delete', function () {
    var cart_detail_id = window.cart_detail_id;
    var cart_item_data = {
      id: cart_detail_id,
    };

    operate_cart_items('delete_cart_detail', cart_item_data);
  });

  // あとで買う削除
  $(document).on('click', '.js-cart-pending-item-delete', function () {
    var pending_item_id = window.cart_pending_item_id;
    var pending_item_data = {
      id: pending_item_id,
    };

    operate_cart_items('delete_cart_pending_item', pending_item_data);
  });

  // 購入手続きへに遷移
  $(document).on('click', '.js-to-order-setting', function () {
    $('form').submit();
  });

  // 引数に指定されたリクエストを実施後、
  // 画面に表示されている内容を更新する
  function operate_cart_items(action, data={}) {
    var url = `${location.pathname}/${action}`;
    $.ajax({
      type: 'POST',
      url: url,
      data: data,
    })
    .done(function (data) {
      $('#js-reload-cart-items').html(data);
      build_modal();
      reload_header_cart_items();
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      alert('正しく反映されませんでした。画面を更新してください。');
    });
  }

  // ヘッダーの買い物カゴを更新
  function reload_header_cart_items() {
    var url = '/show_my_cart';

    $.ajax({
      type: 'GET',
      url: url,
      data: {},
    })
    .done(function (data) {
      $('.js-reload-cart-items').html(data);
    })
    .fail(function (data) {
      alert('正しく反映されませんでした。画面を更新してください。');
    });
  }

  // ----------------------------------------
  // モーダル
  // ----------------------------------------
  $(function() {
    build_modal();
  });

  function build_modal() {
    if ($(".js-cart-modal").length) {
      var options = {
        overlay_opacity: "0.5",
        custom_class: "modal-wrap",
        hide_close: false,
        start_open: false
      };

      $(".js-cart-modal").modaal(options);
    }
  }

  $(document).on("click", ".js-cart-modal-close", function() {
    if ($(".js-cart-modal").length) {
      $('.js-cart-modal').modaal('close');
    }
  });
}
