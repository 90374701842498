export function init_inquiry() {

  $(function(){
    const l_cfn_id = $('#inquiry_inquiry_large_classification_id').val();
    display_l_cfn_default(l_cfn_id);

    // ----------------------------------------
    // 大分類による表示/非表示
    // ----------------------------------------
    $('#inquiry_inquiry_large_classification_id').change(function() {
      const l_cfn_id = $(this).val();
      display_l_cfn_default(l_cfn_id);
    });

    // ----------------------------------------
    // 小分類選択による表示/非表示
    // ----------------------------------------
    $('.cfn_select').change(function() {
      const cfn_id = $(this).val();
      const rci = $(this).find("option:selected").data('rci');
      const rod = $(this).find("option:selected").data('rod');

      $('.cfn_select').prop('disabled', true);
      $(this).prop('disabled', false);

      // 注文番号・WEB問合せ番号表示
      display_orderno_or_webid(rod);

      // 商品番号表示
      display_catalog_item_cd(rci);

      // 問い合わせ項目表示
      display_inquiry_items(cfn_id);
    });


    // ----------------------------------------
    // 大分類・小分類表示
    // ----------------------------------------
    function display_l_cfn_default(l_cfn_id) {
      const selected = $('#inquiry_classification_id' + l_cfn_id).find("option:selected");
      const cfn_id = selected.val();
      const rci = selected.data('rci');
      const rod = selected.data('rod');

      $('.cfn_select').prop('disabled', true);
      $('#inquiry_classification_id' + l_cfn_id).prop('disabled', false);

      // 注文番号・WEB問合せ番号表示
      display_orderno_or_webid(rod);

      // 商品番号表示
      display_catalog_item_cd(rci);

      // 問い合わせ項目表示
      display_inquiry_items(cfn_id);
    }

    // ----------------------------------------
    // 注文番号・WEB問合せ番号表示
    // ----------------------------------------
    function display_orderno_or_webid(rod) {
      if(rod == true) {
        $('#orderno_or_webid').show();
      } else {
        $('#orderno_or_webid').hide();
      }
    }

    // ----------------------------------------
    // 商品番号表示
    // ----------------------------------------
    function display_catalog_item_cd(rci) {
      if(rci == true) {
        $('#catalog_item_cd').show();
      } else {
        $('#catalog_item_cd').hide();
      }
    }

    // ----------------------------------------
    // 問い合わせ項目表示
    // ----------------------------------------
    function display_inquiry_items(cfn_id) {
      const tag = inquiry_items_parent_tag();
      const item_table = $(tag + '.inquiry_items[data-cfn_id="' + cfn_id + '"]');
      $(tag + '.inquiry_items').hide();
      item_table.show();
    }

    function inquiry_items_parent_tag() {
      const tag_pc = 'table';
      const tag_sp = 'dl';
      return is_sp() ? tag_sp : tag_pc;
    }

    // ----------------------------------------
    // PC/SP判定
    // ----------------------------------------
    // $('.js-init-inquiry.sp')がある場合はSP
    function is_sp() {
      return $('.js-init-inquiry.sp').length > 0;
    }
  });

}
