// 「在庫ありのみ表示」ボタンを押したときの挙動
$(function(){
    $("form#product_search_sp").each(function(){
        $form =$(this);

        $(".submit_link").click(function(){
            $form.find("#product_search_submit").click();
        });
    });
});
