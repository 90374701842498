// ヘッダー部のサジェストの挙動
$(function(){

    // サジェスト範囲外をクリックしたらサジェスト欄を閉じる
    $(document).on('click mousedown', function(event) {
      if (!$(event.target).closest('.header-suggest').length) {
        $(".header-suggest").addClass("hidden");
      }
    });

    // キーワード欄にフォーカスしたとき、入力したとき
    var jqxhr = null;
    $(".header_search_keyword").on('input focus', function(){
        $header_suggest = $(".header-suggest");
        request_url = $(this).data("auto-complete-url");
        target_shops = $(this).data("shop-codes");
        target_price = $(this).data("price-code");
        q = $(this).val();
        if(q.length < 4 || q.length > 128){ // 4文字未満だったらサジェスト欄を非表示にして戻る
            $header_suggest.addClass("hidden");
            return true;
        }

        // 前回のが通信中だったら前回のを中断する
        // ただしfail(), always()は実行される
        if (jqxhr) jqxhr.abort();

        // ASにリクエストを投げる
        jqxhr = $.ajax({
            url: request_url,
            dataType: "jsonp",
            data: {
                gf: 'vcd', // 商品でグループ検索する
                q: q,
                opt_sc: target_shops,
                ['opt_' + target_price]: '0~'
            }
        }).done(function(result) {
            // ヒットしていたら
            if(can_open_suggest_area(result)){
                append_keywords(result); // キーワード候補を入れる
                append_items(result); // 商品候補を入れる
                $header_suggest.removeClass("hidden");
            } else { // ヒットしていなかったらサジェスト欄を非表示
                $header_suggest.addClass("hidden");
            }
        });
    });

    // キーワード候補をクリックしたら
    $(".header-suggest-keyword__list").on("click", "li", function(){
        $form = $(this).closest(".header-search").find(".header_search_form");
        $keyword = $form.find(".header_search_keyword");
        value = $(this).find("a").text();
        $keyword.val(value); // フォームにキーワードを入れてsubmit
        $form.find(".header-search-submit").click();
    });

    // ASの検索にヒットしていたら
    function can_open_suggest_area(result){
        if(result['suggestions']) return true;
        if(result['items']) return true;
        return false;
    }

    // キーワード候補を入れる
    function append_keywords(result){
        $header_suggest = $(".header-suggest");
        $header_suggest_keyword_list = $header_suggest.find(".header-suggest-keyword__list");
        $header_suggest_keyword_template = $("#header-suggest-keyword-template").find("li");
        $header_suggest_keyword_list.empty(); // 表示欄を空
        $header_suggest_keyword_list.closest(".header-suggest-keyword").addClass("hidden"); // 表示欄を非表示

        // キーワード候補を入れる
        $.each(result['suggestions'], function(index, keyword){
            $tmp_keyword = $header_suggest_keyword_template.clone();
            $tmp_keyword.find("a").text(keyword);
            $header_suggest_keyword_list.append($tmp_keyword);
            $header_suggest_keyword_list.closest(".header-suggest-keyword").removeClass("hidden");
        });
    }

    // 商品候補を入れる
    function append_items(result){
        function thumbnail(tn) {
            return (tn ? tn : "/images/common/noimage.png");
        }
        $header_suggest = $(".header-suggest");
        $header_suggest_item_list = $header_suggest.find(".header-suggest-item__list");
        $header_suggest_item_template = $("#header-suggest-item-template").find("li");
        $header_suggest_item_list.empty(); // 表示欄を空に
        $header_suggest_item_list.closest(".header-suggest-item").addClass("hidden"); // 表示欄を非表示

        // 商品候補を入れる
        $.each(result['items'], function(index, item){
            $tmp_item = $header_suggest_item_template.clone();
            $tmp_item.find("a").attr("href", item["url"]);
            $tmp_item.find("img").attr("src", thumbnail(item["tn"]));
            $tmp_item.find("img").attr("alt", item["title"]);
            $tmp_item.find(".header-suggest-item__code").text(item["ccd"]);
            $tmp_item.find(".header-suggest-item__name").text(item["title"]);
            $tmp_item.find(".header-suggest-item__capa").text(item["un"]);
            $header_suggest_item_list.append($tmp_item);
            $header_suggest_item_list.closest(".header-suggest-item").removeClass("hidden");
        });
    }
});
