import './application.scss';
import { init_reviews } from './reviews';
import { init_order_comfirm } from './order_confirm';
import { init_cart } from './cart';
import { init_order_setting } from './order_setting';
import { init_inquiry } from './inquiry';
import { init_customers } from './customers';
import { init_form } from './form';
import { init_mypage_top } from './mypage_top';
import { init_add_cart } from './add_cart';
import { init_quick_order } from './quick_order';
import { init_order } from './order';
import { init_favorite } from './favorite';
import { init_catalog_item } from './catalog_item';
import { init_invoice_download } from './purchase_historie';
import { init_password_new } from './password_new';
import Rails from '@rails/ujs';
import 'polyfill-array-includes';

// 検索結果用
import './search/search_form/checkbox_link'
import './search/search_form/submitable_element'
import './search/search_form/removable'
import './search/search_form/display_in_stock'
import './search/search_form/select_sort'
import './search/search_form/switch_display_type'
import './search/search_form/paginatable'
import './search/search_form/color'
import './search/search_form/category'

// 検索結果用(SP)
import './search/sp/search_form/paginatable'
import './search/sp/search_form/select_sort'
import './search/sp/search_form/submit_link'
import './search/sp/search_form/removable'
import './search/sp/search_form/category'

// ヘッダー部のサジェスト用
import './header_suggest'

Rails.start();

$(function () {
  //class="js-fav-countupがあれば"
  if ($('.js-fav-countup').length) {
    init_reviews();
  }
});

$(function () {
  if ($('.js-init-order-confirm').length) {
    init_order_comfirm();
  }
});

$(function () {
  if ($('.cart-section').length) {
    init_cart();
  }
});

$(function () {
  if ($('.js-init-order-setting').length) {
    init_order_setting();
  }
});

$(function() {
  if($('.js-init-inquiry').length){
    init_inquiry();
  }
});

$(function() {
  if($('.js-init-customers').length){
    init_customers();
  }
});

$(function() {
  if($('.js-init-form').length){
    init_form();
  }
});

$(function() {
  if($('.js-init-mypage_top').length){
    init_mypage_top();
  }
});

$(function() {
  $('input.js-not_write_name, input.js-accept_write_name').click(function() {
    $("#js-no_selected_product_option").hide();
    $("#js-selected_product_option").show();
    if($(this).val() == "not_write_name") {
      $("#js-product_option").hide();
    } else {
      $('#js-product_option').show();
    }
  });
});

$(function() {
  if($('.js-insert-cart').length){
    init_add_cart();
  }
});

$(function () {
  if ($('.js-init-quick-order, .js-init-quick-order-sp').length) {
    init_quick_order();
  }
});
$(function() {
  if($('.js-order-cancel').length){
    init_order();
  }
});

$(function() {
  init_favorite();
});

$(function() {
  if($('.js-init-catalog_item').length){
    init_catalog_item();
  }
});

$(function() {
  if($('.js-invoice-download').length) {
    init_invoice_download();
  }
});

$(function () {
  if ($('.js-init-password-new').length) {
    init_password_new();
  }
});