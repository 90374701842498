// 「カテゴリーでで検索」の挙動
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        // 大カテゴリー表示の場合で大カテゴリーをクリックしたとき
        $(".search_by_large_category .large_category_link").click(function(){
            $checkbox = $(this).next();
            $checkbox.prop("checked", true);
            $form.find("#product_search_submit").click();
        });

        // 中カテゴリー表示の場合で大カテゴリーをクリックしたとき
        $(".search_by_middle_category .large_category_link").click(function(){
            $checkbox = $(this).next();
            $checkbox.prop("checked", false);
            $(this).find('input[type="checkbox"]').each(function(){
                $(this).prop("checked", false);
            });
            $form.find("#product_search_submit").click();
        });

        // 中カテゴリー表示の場合で中カテゴリーをクリックしたとき
        $(".search_by_middle_category .middle_category_link").click(function(){
            $checkbox = $(this).next();
            $checkbox.prop("checked", true);
            $(this).addClass("is-current");
            $form.find("#product_search_submit").click();
        });

        // 小カテゴリー表示の場合で大カテゴリーをクリックしたとき
        $(".search_by_small_category .large_category_link").click(function(){
            $checkbox = $(this).next();
            $checkbox.prop("checked", false);
            $(this).closest(".category-side").each(function(){
                $(this).find(".is-current").each(function(){
                    $(this).removeClass("is-current");
                });
                $(this).find('input[type="checkbox"]').each(function(){
                    $(this).prop("checked", false);
                });
            });
            $form.find("#product_search_submit").click();
        });

        // 小カテゴリー表示の場合で中カテゴリーをクリックしたとき
        $(".search_by_small_category .middle_category_link").click(function(){
            $checkbox = $(this).next();
            $checkbox.prop("checked", false);
            $(this).removeClass("is-current");
            $(this).closest(".category-side-list__item").find('.small_category_link').each(function(){
                $(this).removeClass("is-current");
                $(this).next().prop("checked", false);
            });
            $form.find("#product_search_submit").click();
        });

        // 小カテゴリー表示の場合で小カテゴリーをクリックしたとき
        $(".search_by_small_category .small_category_link").click(function(){
            $checkbox = $(this).next();
            if($(this).hasClass("is-current"))
            {
                $(this).removeClass("is-current");
                $checkbox.prop("checked", false);
            } else {
                $(this).addClass("is-current");
                $checkbox.prop("checked", true);
            }
            $form.find("#product_search_submit").click();
        });
    });
});
