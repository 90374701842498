// 「サムネイル」「リスト」を押下したときの挙動
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        $(".search-control .search-display__btn").click(function(){
            if($(this).hasClass("is-current")) return;
            type = $(this).data("value");
            $form.find("#product_search_display_type").val(type);
            $(this).closest(".search-display").find(".is-current").removeClass("is-current");
            $(this).addClass("is-current");
            $now_display = $(".item-wrap:not(." + type + ")");
            $new_display = $(".item-wrap." + type);
            $now_display.fadeOut("fast", function(){
                $new_display.fadeIn("fast");
            });
        });
    });
});
