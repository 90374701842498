export function init_invoice_download() {
  $(document).on('click', '.js-invoice-download', function() {
    // urlがセットされていたら処理を行わない
    const download_button = $(this)
    let a_html = download_button.children('a')

    if (a_html.length) {
      a_html[0].click();
      return;
    }

    const accept_order_id = $(this).attr('data-accept-order-id');
    const url = '/mypage/purchase_histories/invoice_download';

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        accept_order_id: accept_order_id,
      },
      dataType: 'json'
    })
      .done(function (data) {
        // PDF取得不可 エラーメッセージ
        if (data.error_message) {
          add_error_html(accept_order_id, data.error_message)
          return;
        }

        a_html = '<a href="' + data.presigned_url + '"></a>'
        download_button.append(a_html);
        download_button.children('a').attr('target', '_blank');
        download_button.children('a')[0].click();
      })
      .fail(function () {
        // Ajax通信失敗 エラーメッセージ
        if (download_button.parents('article.order-history').find('.invoice_download-error').length == 0) {
          const ajax_error = 'ダウンロードに失敗しました。画面を更新して再度お試しください。'
          add_error_html(accept_order_id, ajax_error)
        }
        return;
      });
  });

  function add_error_html(accept_order_id, error_message) {
    $('#invoice_download-error-' + accept_order_id + ' .form-error__txt').html(error_message);
    $('#invoice_download-error-' + accept_order_id).addClass('form-error form-invoice-download-error')
  }
}
