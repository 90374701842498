export function init_order_setting() {

  // アドレスのチェックをフォームに反映
  $(document).on('click', '.js-change-shipping-address', function() {
    var s_customer_id = $('.cart-way-list input:checked').val();
    var data = $('.data_s_customer_id_' + s_customer_id).html();
    $('.js-hidden-shipping-customer-id').val(s_customer_id);
    $('.setting_address').html(data);
  });

  // クーポンコードを入力するをクリック
  $(document).on('click', '.js-direct-coupon', function() {
    toggle_confirm_button($('.js-direct-coupon-code').val().length);
  });

  // クーポンコードが入力された時
  $(document).on('input', '.js-direct-coupon-code', function() {
    toggle_confirm_button($(this).val().length);
  });

  // 今回利用可能なクーポン一覧から選択するをクリック
  $(document).on('click', '.js-coupon-list', function() {
    toggle_confirm_button($('.js-selectable-coupons input:checked').length);
  });

  // 利用可能なクーポンをクリック
  $(document).on('click' , '.js-selectable-coupon', function() {
    $('.js-set-coupon').removeClass('is-disabled');
  });

  // クーポンを追加するをクリック
  // 手入力のクーポンコードが優先される
  $(document).on("click",".js-set-coupon", function() {
    const disabled = $('.js-set-coupon').hasClass('is-disabled');
    if (disabled == false) {
      if ($('.js-direct-coupon').prop('checked')) {
      // 「クーポンコードを入力する」が選択されていた場合
        const input_coupon_code = $('.js-direct-coupon-code').val();
        $.ajax({
          type: 'GET',
          url: 'find_coupon_from_code',
          data: {
            code: input_coupon_code
          },
          dataType: "json"
        })
        .done(function(data) {
          if (data['coupon'] != null) {
            const coupon_code = data['coupon']['code'];
            const coupon_name = data['coupon']['name']
            $('.js-hidden-coupon-code').val(coupon_code);
            $(".js-coupon-show").text(coupon_name);
            $(".js-coupon-error").hide();
            $('.js-selectable-coupons input:checked').prop('checked', false);
          } else {
            $('.js-direct-coupon-code').val(input_coupon_code);
            $(".js-coupon-error").show();
          }
        })
        .fail(function(data) {
          alert('正しく反映されませんでした。画面を更新してください。')
        });
      } else if ($('.js-coupon-list').prop('checked')) {
      // 「今回利用可能なクーポン一覧から選択する」が選択されていた場合
        const coupon_code = $('.js-selectable-coupons input:checked').val();
        const coupon_name = $('.js-selectable-coupons input:checked').parent().text();
        $('.js-hidden-coupon-code').val(coupon_code);
        $(".js-coupon-show").text(coupon_name);
        $(".js-coupon-error").hide();
        $('.js-direct-coupon-code').val('');
      }
    }
  });

  // クーポンが選択されていたらボタン有効化
  function toggle_confirm_button(coupon) {
    if (coupon) {
      $('.js-set-coupon').removeClass('is-disabled');
    } else {
      $('.js-set-coupon').addClass('is-disabled');
    }
  }
}
