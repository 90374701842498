export function init_reviews() {

  $(function(){
    // ----------------------------------------
    // 参考になったボタン
    // ----------------------------------------
    $(document).on('click', '.js-fav-countup', function(){
      const review_id = $(this).data('review_id');
      const url = location.pathname + '/review_count_up'
      const button = $(this);

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        data: {
          review_id: review_id
        }
      })
      .done(function(data) {
       if(data.error) {
          alert(data.error + '画面を更新してください。')
        }
        else {
          $("#fav_review_id_" + data.id).find('span').html(data.fav_count);
          button.remove();
        }
      })
      .fail(function(data) {
        alert('正しく反映されませんでした。画面を更新してください。')
      });
    });

  });

}
