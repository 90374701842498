// 「カテゴリーでで検索」の挙動
$(function(){
    $("form#product_search_sp").each(function(){
        $form =$(this);

        // 「すべての条件を解除する」を押下したとき
        $(".category_release").click(function(){
            $(this).closest(".search-category").each(function(){
                $(this).find("input[type='checkbox']").prop("checked", false);
            });

            $form.find("#product_search_submit").click();
        });

        // すべて[選択]を押下したとき
        $(".choice_children").click(function(ev){
            checkParentCategory($(this));

            $form.find("#product_search_submit").click();
        });

        // すべて[解除]を押下したとき
        $(".release_children").click(function(){
            $(this).closest("ul").each(function(){
                $(this).parent('li').prev('li').find("input[type='checkbox']").prop("checked", false);
                $(this).find("input[type='checkbox']").prop("checked", false);
            });

            $form.find("#product_search_submit").click();
        });

        // 小カテゴリー[選択]を押下したとき
        // 中カテゴリー[選択]を押下したとき
        // 大カテゴリー[選択]を押下したとき
        $(".choice_small_category, .choice_middle_category, .choice_large_category").click(function(){
            $(this).next().prop("checked", true);
            checkParentCategory($(this));

            $form.find("#product_search_submit").click();
        });

        // [解除]を押下したとき
        $(".release").click(function(){
            $(this).next().prop("checked", false);

            $form.find("#product_search_submit").click();
        });
    });

  function checkParentCategory($child) {
    $child.closest("ul").each(function(){
      let target;
      if ($(this).hasClass('js-child-categories')) {
        // カテゴリ全て
        target = $(this).parent('li').prev('li').find("input[type='checkbox']");
        checkParentCategory(target);
      } else {
        // ショップ全て
        target = $(this).find("li:first > input[type='checkbox']");
      }

      target.prop("checked", true);
    });
  }
});
