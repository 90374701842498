export function init_favorite() {

  $(function() {
    // お気に入り登録ボタン
    if ($(".js-favorite-item-create-button").length) {
      $(".js-favorite-item-create-button").on('click', function() {
        if ($(this).hasClass('js-in-progress') || $(this).hasClass('is-current')) return
        $(this).addClass('js-in-progress');

        $.ajax({
          url: '/mypage/favorite_items',
          type: 'POST',
          dataType: 'json',
          data : {
            shop_slug: $(this).data('shop-slug'),
            catalog_item_cd: $(this).data('catalog-item-cd')
          },
          context: this,
        }).done(function(data) {
          if (data.is_success){
            $(this).addClass('is-current');
            $(this).html($(this).data('success-text'));
          } else if (!data.is_signed_in) {
            return location.href = '/accounts/sign_in';
          }
        }).always(function(){
          $(this).removeClass('js-in-progress');
        });
      });
    }

    // モーダル お気に入りグループ更新
    if ($(".js-favorite-group-update-confirm").length) {
      $(".js-favorite-group-update-confirm").on('click', function() {
        const name = $(this).data('groupname');
        const html = `お気に入りグループ「${name}」を変更します。<br>この動作は取り消しできません。`;
        $('#update_group_name').val(name);
        $('.js-favorite-group-update-message').closest('form').attr('action', $(this).data('path'));
        $('.js-favorite-group-update-message').html(html);
      });
    }

    // モーダル お気に入りグループ削除
    if ($(".js-favorite-group-delete-confirm").length) {
      $(".js-favorite-group-delete-confirm").on('click', function() {
        const name = $(this).data('groupname');
        const html = `お気に入りグループ「${name}」を削除します。よろしいですか？<br>この動作は取り消しできません。`;
        $('.js-favorite-group-delete-message').closest('form').attr('action', $(this).data('path'));
        $('.js-favorite-group-delete-message').html(html);
      });
    }

    // モーダル お気に入り商品削除
    if($(".js-favorite-item-delete-comfirm").length) {
      $(".js-favorite-item-delete-comfirm").on('click', function() {
        const name = $(this).data('groupname');
        const html = `この商品を「${name}」から削除します。よろしいですか？<br>この動作は取り消しできません。`;
        $('.js-favorite-item-delete-massage').html(html);
        $('.js-favorite-item-delete-massage').closest('form').attr('action', $(this).data('path'));
      });
    }

    // モーダル お気に入り商品移動・コピー
    if ($(".js-favorite-item-updatecopy-comfirm").length) {
      $(".js-favorite-item-updatecopy-comfirm").on('click', function() {
        const id = $(this).data('groupid');
        const copyPath = $(this).data('copy-path');
        const updatePath = $(this).data('update-path');
        const origin_selctions = $('.js-favorite-item-origin_selections').children().clone();
        origin_selctions.children(`option[value=${id}]`).remove();
        $('.js-favorite-item-origin_selections').closest('form').find('.select').html(origin_selctions);
        $(".js-favorite-item-copy").data('path', copyPath);
        $(".js-favorite-item-update").data('path', updatePath);
      });
     }

    // モーダル お気に入り商品移動
    if ($(".js-favorite-item-update").length) {
      $(".js-favorite-item-update").on('click', function() {
        const form = $(this).closest('form')
        form.attr('action', $(this).data('path'));
        form.find('[name=_method]').val('put');
        form.submit();
      });
    }

    // モーダル お気に入り商品コピー
    if ($(".js-favorite-item-copy").length) {
      $(".js-favorite-item-copy").on('click', function() {
        const form = $(this).closest('form')
        form.attr('action', $(this).data('path'));
        form.find('[name=_method]').val('');
        form.submit();
      });
    }

  });

}
