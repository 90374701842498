// 検索条件の「解除」「すべて解除」を押下したとき用
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        // 解除
        $(".condition_remove_button").click(function(){
            // チェックボックス系の解除
            $(this).closest(".search-side").find("input[type='checkbox']").each(function(){
                $(this).prop("checked", false);
            });
            // aタグでon,offを表現している所の解除
            $(this).closest(".search-side").find(".is-current").each(function(){
                $(this).removeClass("is-current");
            });
            // 価格のところの解除
            $(this).closest(".search-side").find("input[type='text']").each(function(){
                $(this).val("");
            });
            $form.find("#product_search_submit").click();
        });

        // すべて解除
        $(".condition_all_remove_button").click(function(){
            location.href = $(this).data("url");
        });
    });
});
