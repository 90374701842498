export function init_add_cart() {
// バリデーション
  // ネーム入りinputフィールド_エラーメッセージを表示
  $('.js-input-insert-name').on("input", function() {
    if ($(this).val() == "") {
      // inputフィールドが空欄時に、エラーメッセージを表示
      $('.js-insert-name-error-message').html('<p class="product-option__error js-insert-name-errror-message">ネーム記入欄が未記入です</p>');
    } else {
      // inputフィールドに文字がある場合、エラーメッセージを非表示
      $('.product-option__error').remove();
    }
  });

  // 名入れフラグ、名入れがエラーの値の時、買い物カゴに入れるボタンを非活性化
  $('.js-input-insert-name-flg, .js-input-insert-name').on("change", function() {
    const insert_name_flg = $(".js-input-insert-name-flg:checked").val();
    const insert_name = $(".js-input-insert-name").val();
    if (insert_name_flg == "on" && insert_name == '') {
      // フラグon且つネーム文字無しの場合、ボタンを非活性化
      $('.js-add-cart').addClass("is-disabled");
    } else if (insert_name_flg == null && insert_name != '') {
      // フラグoff且つネーム文字ありの場合、ボタンを非活性化
      $('.js-add-cart').addClass("is-disabled");
    } else {
      // 上記以外の場合、ボタンを活性化
      $('.js-add-cart').removeClass("is-disabled");
    }
  });

// 買い物カゴにカタログ商品を入れる
  // 同意必須カタログ商品コード初期値
  let agreed_catalog_item_cds = [];
  let checked_duplicate_agreed_catalog_item_cds = String($('.js-agree_catalog_items').data('val')).split(",");

// 商品詳細_買い物カゴに入れるボタン
  // 買い物カゴに入れるボタンを押下時、発火
  $('.js-add-cart').on("click", function(e) {
    // 買い物カゴに入れるカタログ商品情報を格納
    let item = $(this).data('item');
    item['amount'] = $(".js-input-amount").val();
    item['insert-name'] = $(".js-input-insert-name").val();

    if(item['amount'] < 1){
      alert('数量には1以上を指定してください');
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    const target_modal = '#modalAgree';

    if ( skip_agreement(item) ) {
      // 商品を買い物カゴに入れましたモーダルをtargetに格納する
      const target = $('#modalCartIn').html();
      // 同意モーダルをtargetに入れ替え表示
      $(target_modal).html(target).fadeIn();

      add_cart(item, false);

    } else {
      $(`${target_modal} .js-add-cart-via-agreement`).data('item', item);
    }
  });

  // 一括で買い物カゴに入れるボタンを押下時、発火
  $('.js-add-cart-in-bulk').on("click", function() {
    const $input_amounts = $("input[name='js-variation-table-input']");
    const agreement_required_items = set_agreement_required_items($input_amounts);
    const orderd_items = set_orderd_items($input_amounts);

    if ( agreement_required_items.length ) {
      // カートに入れる商品を data 属性にセット
      $('.js-agreement-in-bulk-button').data('orderd-items', orderd_items);

      prepare_agreement_message_in_bulk(agreement_required_items);
      open_agreement_in_bulk_modal();
    } else {
      // 同意が必要なかった場合
      $.ajax({
        type: 'POST',
        url: '/add_cart_in_bulk',
        data: {
          shop_slug: $(this).data('shop-slug'),
          catalog_items: orderd_items,
        }
      })
      .done(function(data) {
        // カート追加済みモーダル開く
        open_cart_in_bulk_modal(data);
        reload_cart_items();
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        close_agreement_in_bulk_modal();
        alert('正しく反映されませんでした。画面を更新してください。')
      });
    }

    return false;
  });

  // 他のバリエーション_買い物カゴに入れるボタンを押下時、発火
  $('.js-add-cart-catalog-item-variation-button').on("click", function(e) {
    // 買い物カゴに入れるカタログ商品情報を格納
    let item = $(this).data('item');
    item['amount'] = $(this).closest('.js-variation-cart-button').find('input').val();
    item['insert-name'] = '';

    if(item['amount'] < 1){
      alert('数量には1以上を指定してください');
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    const target_modal = `#modalAgreeInOtherVariation${item['catalog-item-cd']}`

    if ( skip_agreement(item) ) {
      // 商品を買い物カゴに入れましたモーダルをtargetに格納する
      const target = $(`#modalCartIn${item['catalog-item-cd']}`).html();
      // 同意モーダルをtargetに入れ替え表示
      $(target_modal).html(target).fadeIn();

      add_cart(item, false);
    } else {
      $(`${target_modal} .js-add-cart-via-agreement`).data('item', item);
    }
  });

  // お気に入り_買い物カゴに入れるボタンを押下時、発火
  $('.js-add-cart-favorite-button').on("click", function(e) {
    // 買い物カゴに入れるカタログ商品情報を格納
    let item = $(this).data('item');
    item['amount'] = $(this).closest('.js-favorite-cart-button').find('input').val();
    item['insert-name'] = '';

    if(item['amount'] < 1){
      alert('数量には1以上を指定してください');
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    const target_modal = `#modalAgreeFavorite${item['catalog-item-cd']}`


    if ( skip_agreement(item) ) {
      // 商品を買い物カゴに入れましたモーダルをtargetに格納する
      const target = $(`#modalCartIn${item['catalog-item-cd']}`).html();
      // 同意モーダルをtargetに入れ替え表示
      $(target_modal).html(target).fadeIn();

      add_cart(item, false);
    } else {
      $(`${target_modal} .js-add-cart-via-agreement`).data('item', item);
    }
  });

  // 購入履歴_買い物カゴに入れるボタンを押下時、発火
  $('.js-add-cart-purchase-history-button').on("click", function() {
    // 買い物カゴに入れるカタログ商品情報を格納
    let item = $(this).data('item');
    item['amount'] = '1';
    item['insert-name'] = '';

    const target_modal = `#modalAgreePurchaseHistory${item['catalog-item-cd']}`

    if ( skip_agreement(item) ) {
      // 商品を買い物カゴに入れましたモーダルをtargetに格納する
      const target = $(`#modalCartIn${item['catalog-item-cd']}`).html();
      // 同意モーダルをtargetに入れ替え表示
      $(target_modal).html(target).fadeIn();

      add_cart(item, false);
    } else {
      $(`${target_modal} .js-add-cart-via-agreement`).data('item', item);
    }
  });

  // カセット_買い物カゴに入れるボタンを押下時、発火
  $('.js-add-cart-cassette-button').on("click", onclick_add_cart);

  function onclick_add_cart(event) {
    // 買い物カゴに入れるカタログ商品情報を格納
    let item = $(event.currentTarget).data('item');
    item['amount'] = '1';
    item['insert-name'] = '';

    const target_modal = `#modalAgreeInCassette${item['catalog-item-cd']}`

    if ( skip_agreement(item) ) {
      // 商品を買い物カゴに入れましたモーダルをtargetに格納する
      const target = $(`#modalCartIn${item['catalog-item-cd']}`).html();
      // 同意モーダルをtargetに入れ替え表示
      $(target_modal).html(target).fadeIn();

      add_cart(item, false);
    } else {
      $(`${target_modal} .js-add-cart-via-agreement`).data('item', item);
    }
  }

  // クイックオーダー_一括で買い物カゴに入れるで発火
  $('.js-add-cart-quick-order').on("click", function() {
    const $input_amounts = $('.js-item-amount');
    const agreement_required_items = set_agreement_required_items($input_amounts);

    // 1つでも0以下の値があればエラー扱い
    let less_than_zero = false;
    $input_amounts.each(function(i, e) {
      const amount = $(e).val();
      if( amount < 1 && $(e).data('item')){
        less_than_zero = true;
        return false;
      }
    });
    if(less_than_zero){
      alert('数量には1以上を指定してください');
      return;
    }

    if ( agreement_required_items.length ) {
      prepare_agreement_message_in_bulk(agreement_required_items);
      open_agreement_in_bulk_modal();

      return false;
    }

    $('form#quick_order').submit();

    return false;
  });

  // 単品_同意必須モーダル_同意した上で買い物カゴに入れるで発火
  $(document).on("click", '.js-add-cart-via-agreement', function() {
    const item = $(this).data('item');

    add_cart(item);
  });

  // 一括_同意必須モーダル_同意した上で買い物カゴに入れるで発火
  $('#modalAgreementInBulk .js-agreement-in-bulk-button').on("click", function(e) {
    after_agreement($(this).data('agreement-required-item-codes'));

    $.ajax({
      type: 'POST',
      url: '/add_cart_in_bulk',
      data: {
        shop_slug: $(this).data('shop-slug'),
        catalog_items: $(this).data('orderd-items'),
      }
    })
    .done(function(data) {
      e.stopPropagation();
      e.preventDefault();

      $('.js-agreement-in-bulk-modal').modaal('close');
      open_cart_in_bulk_modal(data);

      reload_cart_items();
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      close_agreement_in_bulk_modal();
      alert('正しく反映されませんでした。画面を更新してください。')
    });

    return false;
  });


  // クイックオーダー_同意必須モーダル_同意した上で買い物カゴに入れるで発火
  $('#modalAgreementInQuickOrder .js-agreement-in-bulk-button').on("click", function() {
    // 同意履歴の更新が終わるまで待つ
    $.when.apply(
      $, after_agreement($(this).data('agreement-required-item-codes'))
    ).done(function() {
      close_agreement_in_bulk_modal();
      $('form#quick_order').submit();
    })

    return false;
  });

  // 同意ボタンを表示するかの判定
  function skip_agreement(item) {
    return (item['product-description3'] == "")
  }

  // カートへの追加処理
  function add_cart(item, via_agreement = true) {
    $.ajax({
      type: 'POST',
      url: '/add_cart',
      data: {
        shop_slug: item['shop-slug'],
        catalog_item_cd: item['catalog-item-cd'],
        amount: item['amount'],
        insert_name: item['insert-name']
      }
    })
    .done(function() {
      reload_cart_items();

      if ( via_agreement ) {
        after_agreement(new Array(item['catalog-item-cd']));
      }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      close_agreement_modal();
      alert('正しく反映されませんでした。画面を更新してください。');
    });
  }

  // 同意が必要な商品のデータ
  function set_agreement_required_items($input_amounts) {
    let agreement_required_items = [];

    $input_amounts.each(function(i, e) {
      const amount = $(e).val();

      // 有効な注文数が存在する場合
      if (amount > 0) {
        const item = $(e).data('item');

        if ( !skip_agreement(item) ) {
          // 同意が必要な商品
          let agreement_required_item = {};

          agreement_required_item['catalog_item_cd'] = item['catalog-item-cd'];
          agreement_required_item['item_label'] = item['item-label'];
          agreement_required_item['product_description3'] = item['product-description3'];
          agreement_required_items.push(agreement_required_item);
        }
      }
    });

    return agreement_required_items;
  }

  // カートに入れる商品のデータの準備
  function set_orderd_items($input_amounts) {
    let orderd_items = [];

    $input_amounts.each(function(i, e) {
      const amount = $(this).val();

      // 有効な注文数が存在する場合
      if (amount > 0) {
        const item = $(e).data('item');
        let orderd_item = {};

        orderd_item['catalog_item_cd'] = item['catalog-item-cd'];
        orderd_item['size'] = item['size'];
        orderd_item['amount'] = amount;
        orderd_items.push(orderd_item);
      }
    });

    return orderd_items;
  }

  // 複数商品の同意文言準備
  function prepare_agreement_message_in_bulk(agreement_required_items) {
    // 同意の保存処理が必要な商品コードを data 属性にセット
    let agreement_required_item_codes = agreement_required_items.map( function(agreement_required_item) {
      return agreement_required_item['catalog_item_cd'];
    });

    $('.js-agreement-in-bulk-button').data('agreement-required-item-codes', agreement_required_item_codes);

    // モーダルに商品ごとの注意文言を追加
    let $dl = $("<dl />").addClass('modal-message__list');
    $.each(agreement_required_items, function(index, agreement_required_item) {
      $dl.append(`<dt class="modal-message__ttl">${agreement_required_item['item_label']}</dt>`);
      $dl.append(`<dd class="modal-message__data">${agreement_required_item['product_description3']}</dd>`);
    });
    $('.js-agreement-in-bulk-message').empty().append($dl);
  }

  // 同意後の処理
  function after_agreement(item_codes) {
    return $.map(item_codes, function(item_code, i) {
      // 同意済みのカタログ商品コードを配列に格納する
      agreed_catalog_item_cds.push(item_code);
      // 同意済みカタログ商品コードの重複を消す
      checked_duplicate_agreed_catalog_item_cds = $.unique(agreed_catalog_item_cds);
      return agree_catalog_item(item_code);
    });
  }

  // 同意するに保存
  function agree_catalog_item(catalog_item_cd) {
    var url = '/agree_catalog_item'
    return $.ajax({
      type: 'GET',
      url: url,
      data: { catalog_item_cd: catalog_item_cd },
    })
    .done(function (data) {
    })
    .fail(function (data) {
      alert('正しく反映されませんでした。画面を更新してください。');
    });
  }

  // ヘッダー買い物カゴを更新
  function reload_cart_items() {
    var url = '/show_my_cart'
    $.ajax({
      type: 'GET',
      url: url,
      data: {},
    })
    .done(function (data) {
      $('.js-reload-cart-items').html(data);
    })
    .fail(function (data) {
      alert('正しく反映されませんでした。画面を更新してください。');
    });
  }

  // 再入荷申し込みボタン
  $(document).on('click', '.js-entry-restock', function() {
    const item = $(this).data('item');

    let url = '/back_order_notification_request';
    $.ajax({
      type: 'GET',
      url: url,
      data: { catalog_item_cd: item['catalog-item-cd'],
              shop_slug: item['shop-slug'] }
    })
    .fail(function (data) {
      close_restock_modal();
      alert('正しく反映されませんでした。画面を更新してください。');
    })
  });

  // 再入荷申し込みボタン
  $(document).on("click", '.js-restock-modal', function() {
    const item = $(this).data('item');

    load_restock(item);
  });

  function load_restock(item) {
    let url = '/back_order_notification_requestable';
    $.ajax({
      type: 'GET',
      url: url,
      data: { catalog_item_cd: item['catalog-item-cd'] }
    })
    .done(function (data) {
      if ( data == "false") {
        // 再入荷お知らせメールのお申込み完了モーダル
        const target = $('#modalRestockComplete').html();
        $(".modaal-content-container").html(target).fadeIn();
      } else {
        // 再入荷お知らせメールのお申込み完了モーダル
        $(".modal-item__name").text(item['catalog-item-name']);
        $(".modal-item__sub").text(item['product-description2']);
        $(".modal-item__capa").text(item['sales-unit-memo']);
        $(".modal-mail__data").text(item['email']);

        const target = $('#modalRestockEntry').html();
        $(".modaal-content-container").html(target).fadeIn();

        $('.js-entry-restock').data('item', item);
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      alert('正しく反映されませんでした。画面を更新してください。');
      $('.js-restock-modal').modaal('close');
    })
  }

  // ----------------------------------------
  // モーダル
  // ----------------------------------------
  $(function() {
    build_agreement_modal();
    build_restock_modal();

    // ajaxで取得するカセット初期化用のイベント
    // jQueryのイベント処理がうまく動かないのでJSでイベント定義
    document.addEventListener('cassette:init', (event) => {
      const target = event.detail.target;
      const cassette_options = $.extend(base_modal_options(), { before_open: onclick_add_cart });

      build_agreement_modal($(target), cassette_options);
      build_restock_modal($(target), cassette_options);
    });
  });

  //閉じるボタン
  $(document).on("click", ".js-modal-close", function() {
    close_restock_modal();
  });

  $(document).on("click", ".js-restock-modal-close", function() {
    close_restock_modal();
  });

  $(document).on("click", ".js-agreement-modal-close", function() {
    close_agreement_modal();
  });

  $(document).on("click", ".js-agreement-in-bulk-modal-close", function() {
    close_agreement_in_bulk_modal();
  });

  function base_modal_options() {
    const options = {
      overlay_opacity: "0.5",
      custom_class: "modal-wrap",
      hide_close: false,
      start_open: false,
    };

    return options;
  }

  // modaalが元のHTMLを上書きするので、それを抑制するための設定
  // モーダル開いた後に元のHTMLを戻して、閉じた後に追加されたHTMLを消す
  function non_destractive_options() {
    return {
      after_open: function(modal_wrapper) {
        const modaal_id = $(modal_wrapper).attr('id');
        const origin_place = $($('[data-modaal-scope="' + modaal_id + '"]').attr('href'));
        // 同じID持つ要素が複数あるが、modaalが`$('#{id}')`で処理対象取得しているので先頭のみ取得で問題ない
        $('#' + modaal_id + ' .modaal-content-container').contents().clone().appendTo(origin_place);
      },
      after_close: function() {
        $('.modal-hidden').children().each(function(i, elem) {
          $(elem).children(':not(:first)').remove();
        });
      }
    }
  }

  function build_restock_modal(scope = $(document), base_options = base_modal_options()) {
    const target = scope.find(".js-restock-modal");
    if (target.length) {
      const additonal_options = {
        after_close: function() {
          const target = $('#modalLoading').html();
          $("#modalRestock").html(target).fadeIn();
        }
      };
      const options = $.extend(base_options, additonal_options);

      target.modaal(options);
    }
  }

  function build_agreement_modal(scope = $(document), options = base_modal_options()) {
    const target = scope.find(".js-agreement-modal");
    if (target.length) {

      target.modaal($.extend(options, non_destractive_options()));
    }
  }

  function open_agreement_in_bulk_modal() {
    const options = base_modal_options();

    $('.js-agreement-in-bulk-modal').modaal(options);
    $('.js-agreement-in-bulk-modal').click();
  }

  function open_cart_in_bulk_modal(data) {
    const options = base_modal_options();

    $('.modal-item-order').html(data);
    $('.js-cart-in-bulk-modal').modaal(options);
    $('.js-cart-in-bulk-modal').click();
  }

  function close_restock_modal() {
    if ($(".js-restock-modal").length) {
      $('.js-restock-modal').modaal('close');
    }
  }

  function close_agreement_modal() {
    if ($(".js-agreement-modal").length) {
      $('.js-agreement-modal').modaal('close');
    }
  }

  function close_agreement_in_bulk_modal() {
    // 同意画面
    if ($(".js-agreement-in-bulk-modal").length) {
      $('.js-agreement-in-bulk-modal').modaal('close');
    }
    // カートに追加画面
    if ($(".js-cart-in-bulk-modal").length) {
      $('.js-cart-in-bulk-modal').modaal('close');
    }
  }
}
