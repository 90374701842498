export function init_form() {

  $(function(){
    // ----------------------------------------
    // 添付ファイル
    // ----------------------------------------
    // 削除リンク
    $('a.file_delete').on("click", function() {
      const file = $(this).parents('.data_inner_file').find('.file')
      $(this).parents('.data_inner_file').find('.file_cache').remove();
      file.find('.file_txt').hide();
      file.find('input').val('');
    });

    // ファイル選択ボタン
    $('.file_select').on("click", function() {
      const file = $(this).parents('.data_inner_file').find('.file');
      file.find('input').prop('disabled', false);
    });

    // ファイル名表示
    $('input[type=file]').change(function() {
      const file = $(this).prop('files')[0];
      $(this).parents('.data_inner_file').find('.file_txt').show();
      $(this).parents('.data_inner_file').find('.file_txt').find('span').html(file.name);
    });

    // ----------------------------------------
    // エラー表示
    // ----------------------------------------
    // セレクトボックス
    $('select.is-error').each(function(idx, e){
      $(e).parents('.select').addClass('is-error');
    });

    // テキストフィールド・テキストエリア（SPのみ）
    if(is_sp()) {
      $('input.is-error, textarea.is-error').each(function(idx, e){
        $(e).parents('.field').addClass('is-error');
      });
    }

    // ----------------------------------------
    // PC/SP判定
    // ----------------------------------------
    // $('.js-init-form.sp')がある場合はSP
    function is_sp() {
      return $('.js-init-form.sp').length > 0;
    }
  });

}
