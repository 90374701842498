export function init_order_comfirm() {
  // ローディング
  $(function(){
    var loading = $("#js-loading");
    //スクロール禁止
    $("html, body").css("overflow", "hidden");
  });

  $(function(){
    //ローディング非表示
    var loading = $("#js-loading");
    loading.fadeOut();
    //スクロール禁止解除・スクロール位置を0戻す
    $("html, body").removeAttr("style").scrollTop(0);

    // お届け時間の初期表示
    if ($('.js-delivery-date-unspecified').prop('checked')) {
      $('input[name="cart[delivery_time]"]').first().prop('checked', true);
      $('.js-delivery-time-table li:not(:first)').hide();
    } else if ($('.js-fastest-delivery-date').prop('checked')) {
      mask_unselectable_times($('.js-fastest-delivery-date'));
    }
  });

  // 二重送信防止
  $('form#order_form').submit(function() {
    $('.js-submit-order-form').prop('disabled', true);
  });

  // お届け日を変更
  $(document).on('click', '.js-select-delivery-date', function() {

    if ($('.js-delivery-date-unspecified').prop('checked')) {
      $('input[name="cart[delivery_time]"]').first().prop('checked', true);
      $('.js-delivery-time-table li:first').show();
      $('.js-delivery-time-table li:not(:first)').hide();
    } else {
      $('.js-delivery-time-table').children().show();
      mask_unselectable_times($(this));
    }
  });

  // お届け日時を変更
  $(document).on("click",".js-change-delivery-day", function() {
    const delivery_date = $("input[name='cart[delivery_date]']:checked").data('show-date');
    const delivery_time = $("input[name='cart[delivery_time]']:checked").data('show-time');
    let delivery_datetime = '';

    $(".js-show-delivery-date").text(delivery_date);
    $(".js-show-delivery-time").text(delivery_time);

    if (delivery_date == '指定なし') {
      delivery_datetime = delivery_date;
    } else {
      delivery_datetime = delivery_date + delivery_time;
    }
    $(".js-show-delivery-datetime").text(delivery_datetime);
  });

  // 選択できない時間帯をマスク
  function mask_unselectable_times($element) {
    const index = $element.data('selectable-fastest-time-index') || null;
    if (index != null) {
      $('input[name="cart[delivery_time]"]').slice(0, index).closest('li').hide();
    } else {
      $('input[name="cart[delivery_time]"]').slice(0, 1).closest('li').hide();
    }
    $('input:visible[name="cart[delivery_time]"]').first().prop('checked', true);
  }
}
