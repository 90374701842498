export function init_quick_order() {

  $(function(){
    window.search_items = {};
    window.sp_flag = $('.js-init-quick-order-sp').length;
  });

  // 商品コード検索
  $(document).on('input', '.js-search-item-code', function() {
    const search_item_code = $(this).val();
    const $input_field = $(this).closest('.js-item-input-field');

    // firefox対策＋IME対策
    // 入力された商品コードと、今セットされている商品の商品コードが一致する場合はなにもしない
    const search_catalog_item_cd = `${catalog_cd()}${search_item_code}`;
    const current_catalog_item_cd = $input_field.find('.js-catalog-item-code').val();
    if( search_catalog_item_cd.length > 0 && current_catalog_item_cd.length > 0 &&
        search_catalog_item_cd == current_catalog_item_cd ){
      return false;
    }

    // 該当列の商品データとリストを初期化
    reset_field_without_search_item_field($input_field)

    // ２文字以上入力されたら検索開始
    if (search_item_code.length > 1) {
      $.ajax({
        type: 'GET',
        url: `/orders/quick/${catalog_cd()}/search`,
        data: { order_item_cd: search_item_code },
      })
      .done( function(items) {
        search_items = items;

        if (items.length === 0) {
          // 検索結果が 0 件の場合はメッセージを表示
          // ただし、すでに存在している場合には何もしない
          if( $input_field.find('.quick-item-message').length == 0 ){
            $input_field.find('.js-item-summary').append('<p class="quick-item-message">該当する商品がカタログに存在しないか、<br />事前にご登録が必要な商品です。</p>');
          }

        } else if ( items.length == 1 ) {
          // 検索結果が 1 件
          const item = items[0];

          if (item.order_item_cd === search_item_code) {
            // 入力したコードと商品コードが一致する場合は商品を追加
            add_item($input_field, item);
          } else {
            // 入力したコードと商品コードが一致しない場合はリストを表示
            create_suggest_list($input_field);
          }

        } else {
          // 検索結果が 複数件の場合にはサジェスト表示
          create_suggest_list($input_field);
        }
      })
      .fail( function() {
        alert('商品コードの検索に失敗しました。');
      });
    }
  });

  // 商品のリスト以外をクリックしたら、リストを閉じる
  $(document).on('click', function(e) {
    if ( !$(e.input_field).closest('.js-select-item').length ){
      remove_suggest_list();
    }
  });

  // リストから商品を選択
  $(document).on('click', '.js-select-item', function() {
    const input_field_index = $(this).closest('.js-suggest-list').data('input-filed-index');
    const $input_field = $('.js-item-input-field').eq(input_field_index);

    const selected_item_index = $(this).index('li.js-select-item');

    add_item($input_field, search_items[selected_item_index]);
  });

  // 個数を変更したら、小計を自動計算する
  $(document).on('input', '.js-item-amount', function() {
    const $input_field = $(this).closest('.js-item-input-field');
    const price = $input_field.find('.js-item-price').data('price');
    let amount = $(this).val();
    if (!validate_amount(amount)) {
      amount = 0;
    }

    $input_field.find('.js-item-total-price').html(total_price_label(price, amount));
  });

  // 商品候補リストを削除
  function remove_suggest_list() {
    $('.js-suggest-list').remove();
  }

  // 商品候補リストを作成
  function create_suggest_list($input_field) {
    remove_suggest_list();

    let $ul = $("<ul />").addClass('quick-code-option__list');
    $.each(search_items, function(index, item) {
      let $li = $("<li />").addClass('quick-code-option__item js-select-item');
      $li.append(`<span class="quick-code-option__code">${item.order_item_cd}</span>`);
      $li.append(`<span class="quick-code-option__name">${item.catalog_item_name}</span>`);
      $ul.append($li);
    });

    const index = $input_field.index('.js-item-input-field');
    const offset_top = $input_field.offset().top;
    const position = sp_flag ? (offset_top + 45) : (offset_top + 55);
    let $nesting = $("<div />").addClass('quick-code-option js-suggest-list').css('top',`${position}px`);

    set_data_input_filed_index($input_field, $nesting);

    $nesting.append($ul);
    $('.js-item-input-fields').after($nesting);
  }

  // 商品行の初期化
  function reset_field($input_field) {
    reset_search_item_field($input_field)
    reset_field_without_search_item_field($input_field)
    $input_field.find('.js-item-amount').removeData('item')
  }

  // 商品コード以外の商品行の初期化
  function reset_field_without_search_item_field($input_field) {
    remove_suggest_list();
    remove_item($input_field);
    $input_field.find('.quick-item-message').remove();
  }

  // 商品コード入力欄を空にする
  function reset_search_item_field($input_field) {
    $input_field.find('.js-search-item-code').val('');
  }

  // 商品を削除
  function remove_item($input_field) {
    // カタログ商品コード
    remove_catalog_item_code($input_field);
    // 商品概要
    remove_summary($input_field);
    // 金額関連
    remove_price($input_field);
    // 数量
    remove_amount($input_field);
    // 在庫状況
    remove_stock_status($input_field);
    // 削除ボタン
    remove_delete_button($input_field);
  }

  // カタログ商品コードを削除
  function remove_catalog_item_code($input_field) {
    $input_field.find('.js-catalog-item-code').val('');
  }

  // 商品概要を削除
  function remove_summary($input_field) {
    $input_field.find('.js-item-summary').children().hide();

    // 画像
    $input_field.find('.js-item-img').empty();
    // 商品名
    $input_field.find('.js-item-name').empty();
    // 説明
    $input_field.find('.js-item-sub').empty();
    // 購入単位
    $input_field.find('.js-item-capa').empty();
  }

  // 金額関連の削除
  function remove_price($input_field) {
    // 金額を削除
    $input_field.find('.js-item-price').empty();

    // 小計を削除
    $input_field.find('.js-item-total-price').empty();
  }

  // 数量を書き換え&非表示
  function remove_amount($input_field) {
    if (sp_flag) {
      $input_field.find('.js-item-amount').val('0').parent().addClass('is-disabled');
    } else {
      $input_field.find('.js-item-amount').val('0').prop('disabled', true).hide();
    }
  }

  // 在庫状況を削除
  function remove_stock_status($input_field) {
    $input_field.find('.js-item-stock').empty();
  }

  // 削除ボタンを非表示
  function remove_delete_button($input_field) {
    $input_field.find('.js-item-delete-button').hide();
  }

  // 商品を追加
  function add_item($input_field, item) {
    remove_suggest_list();

    // 検索フィールドの商品コードを補完
    complement_search_item_code($input_field, item)
    // 登録用カタログ商品コード
    add_catalog_item_code($input_field, item)
    // 商品概要
    add_summary($input_field, item);
    // 金額関連
    add_price($input_field, item);
    // 数量
    add_amount($input_field, item);
    // 在庫状況
    add_stock_status($input_field, item);
    // 削除ボタン
    add_delete_button($input_field);
  }

  // 選択した商品のコードを検索フィールドに入れる
  function complement_search_item_code($input_field, item) {
    $input_field.find('.js-search-item-code').val(item.order_item_cd);
  }

  // 登録用カタログ商品コードを追加
  function add_catalog_item_code($input_field, item) {
    // 在庫がある商品だったら catalog_item_cd を追加
    if (in_stock(item)) {
      $input_field.find('.js-catalog-item-code').val(item.catalog_item_cd);
    }
  }

  // 商品概要を追加
  function add_summary($input_field, item) {
    // 画像（画像が設定されていない場合はnoimageを出す）
    let item_image = '/images/common/noimage.png';
    if( item.item.main_image != null) { item_image = item.item.main_image.thumbnail; }
    $input_field.find('.js-item-img').html(`<img src="${item_image}" alt="${item.catalog_item_name}">`);
    // 商品名
    $input_field.find('.js-item-name').html(item.catalog_item_name);
    // 説明
    $input_field.find('.js-item-sub').html(item.web_item.product_description2);
    // 購入単位
    $input_field.find('.js-item-capa').html(item.web_item.product_description2);

    $input_field.find('.js-item-summary').children().show();
  }

  // 金額関連の追加
  function add_price($input_field, item) {
    const price = item.classify_single_price.tax_included_price;
    const price_without_tax = item.classify_single_price.price_without_tax;

    // 価格を追加
    $input_field.find('.js-item-price').data('price', price);
    $input_field.find('.js-item-price').html(price_label(price, price_without_tax));

    // 小計を追加
    const amount = (in_stock(item)) ? 1 : 0;
    $input_field.find('.js-item-total-price').html(total_price_label(price, amount));
  }

  // 在庫状況を追加
  function add_stock_status($input_field, item) {
    let str = '';
    const tag = sp_flag ? 'p' : 'span';

    if (in_stock(item)) {
      str = `<${tag} class="quick-ico-stock">在庫あり</${tag}>`;
    } else {
      str =`<${tag} class="quick-ico-stock is-restock">在庫なし</${tag}>`;
    }
    $input_field.find('.js-item-stock').html(str);
  }

  // 数量を書き換え&表示
  function add_amount($input_field, item) {
    let $input = $input_field.find('.js-item-amount');

    if (in_stock(item)) {
      // ここで同意モーダル表示用の data 属性付与
      const data_item = {
        'catalog-item-cd': item.catalog_item_cd,
        'item-label': item.catalog_item_name,
        'product-description3': (item.web_item.product_description3 || '')
      }
      $input.data('item', data_item);
      if (sp_flag) {
        $input.val('1').parent().removeClass('is-disabled');
      } else {
        $input.val('1').prop('disabled', false);
        $input.show();
      }
    } else {
      if (sp_flag) {
        $input.val('0').parent().addClass('is-disabled');
      } else {
        $input.val('0').prop('disabled', true);
        $input.show();
      }
    }

  }

  // 削除ボタンを表示
  function add_delete_button($input_field) {
    $input_field.find('.js-item-delete-button').show();
  }

  // 在庫がある商品
  function in_stock(item) {
    const item_status = [1, 4];
    if (item.web_item && item_status.includes(item.web_item.status) &&item.item && item.item.stock_item && item.item.stock_item.quantity && item.item.stock_item.quantity > 0) {
      return true;
    }

    return false;
  }

  // 金額表示
  function price_label(price, price_without_tax) {
    if (sp_flag) {
      return `価格：${price_format(price)}<span>（税抜${price_format(price_without_tax)}）</span>`;
    } else {
      return `<b>${price_format(price)}</b>(${price_format(price_without_tax)})`;
    }
  }

  // 小計計算
  function total_price_label(price, num=0) {
    const total_price = price_format(Number(price) * Number(num));

    if (sp_flag) {
      return `小計（税込）<span class="cart-subtotal__data">${total_price}</span>`;
    } else {
      return total_price;
    }
  }

  // 金額のフォーマット
  function price_format(price) {
    return `${price.toLocaleString()}円`;
  }

  // カタログID取得
  function catalog_cd() {
    return $('#js-web-catalog-cd').val();
  }

  // アクティブな入力行のインデックスを data 属性として追加
  function set_data_input_filed_index($input_field, $target) {
    $target.data('input-filed-index', '');

    const input_filed_index = $input_field.index('.js-item-input-field');
    $target.data('input-filed-index', input_filed_index);
  }

  function validate_amount(amount){
    const reg = new RegExp(/^[0-9]*$/);
    const res = reg.test(amount);
    return res;
  }

  // ----------------------------------------
  // モーダル
  // ----------------------------------------
  $(function() {
    build_modal();
  });

  // 削除ボタンを押下
  $(document).on("click", ".js-quick-order-modal", function() {
    const $input_field = $(this).closest('.js-item-input-field');

    // $input_field がある場合は個別削除の対応
    if ($input_field.length) {
      set_data_input_filed_index($input_field, $('#modalDelete'));
    }
  });

  // [モーダル内]削除しないで閉じる
  $(document).on("click", ".js-quick-order-modal-close", function() {
    close_modal();
  });

  // [モーダル内]商品を個別に削除
  $(document).on("click", ".js-quick-order-delete", function() {
    const input_filed_index = $('#modalDelete').data('input-filed-index');
    const $input_field = $('.js-item-input-field').eq(input_filed_index);

    if ($input_field.length) {
      reset_field($input_field);
    }

    close_modal();
  });

  // [モーダル内]商品全て削除
  $(document).on("click", ".js-quick-order-all-delete", function() {
    $('.js-item-input-field').each( function(i, e) {
      reset_field($(e));
    });

    close_modal();
  });

  // モーダルを作成
  function build_modal() {
    if ($(".js-quick-order-modal").length) {
      const options = {
        overlay_opacity: "0.5",
        custom_class: "modal-wrap",
        hide_close: false,
        start_open: false
      };

      $(".js-quick-order-modal").modaal(options);
    }
  }

  // モーダルを閉じる
  function close_modal() {
    if ($(".js-quick-order-modal").length) {
      $('.js-quick-order-modal').modaal('close');
    }
  }

}
