export function init_mypage_top() {

  $(function(){
    // ----------------------------------------
    // レビュー削除
    // ----------------------------------------
    $(document).on('click', '.review_delete', function(){
      const review_id = $('#link_review_delete').data('review_id')

      $.ajax({
        type: 'POST',
        url: '/mypage/delete_review',
        dataType: 'json',
        data:{
          review_id: review_id
        }
      })
      .done(function(result) {
        reloadCommon();
        modalClose();

       if(result.error) {
          alert(result.error + '画面を更新してください。')
        }
        else {
          $('#latest_review').html(result.html);
        }

        return false;
      })
      .fail(function(err){
        modalClose();
        alert('正しく反映されませんでした。画面を更新してください。')
      });
    });

    // jsファイル再読み込み
    function reloadCommon() {
      $.getScript("/javascripts/common.js");
    }

  });

}
