// 「在庫ありのみ表示」ボタンを押したときの挙動
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        $(".search-control #display_in_stock_button").click(function(){
            if($(this).hasClass("is-current"))
            {
                $(this).removeClass("is-current");
                $form.find("#product_search_stf").val("");
            } else {
                $(this).addClass("is-current");
                $form.find("#product_search_stf").val("1");
            }
            $form.find("#product_search_submit").click();
        });
    });
});
