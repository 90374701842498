export function init_catalog_item() {

  $(function(){
    // ----------------------------------------
    // バリエーション選択（SP）
    // ----------------------------------------
    // 商品選択
    $('.product-choice .product-choice-list__label').on("click", function() {
      if(is_sp()) {
        const $radio = $(this).find('input[type="radio"]');
        const $link = $(this).parents('.product-choice-content').find('.product-choice-content__btn a');
        const href = $(this).find('.product-choice-list__btn a').attr('href');
        $radio.prop('checked', true);
        $link.attr('href', href);

        return false;
      }
    });

    // ----------------------------------------
    // PC/SP判定
    // ----------------------------------------
    // $('.js-init-catalog_item.sp')がある場合はSP
    function is_sp() {
      return $('.js-init-catalog_item.sp').length > 0;
    }
  });

}
