// 「カラーで絞り込む」の挙動
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        $(".search-color").click(function(){
            $checkbox = $(this).find("input[type='checkbox']");
            if($(this).hasClass("is-current"))
            {
                $(this).removeClass("is-current");
                $checkbox.prop("checked", false);
            } else {
                $(this).addClass("is-current");
                $checkbox.prop("checked", true);
            }
            $form.find("#product_search_submit").click();
        });
    });
});
