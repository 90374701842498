// 検索条件の「ｊ」を押下したとき用
$(function(){
    $("form#product_search_sp").each(function(){
        $form =$(this);

        // 解除
        $(".condition_remove_button").click(function(){
            // チェックボックス系の解除
            $(this).closest(".search-sort-list").find("input[type='checkbox']").each(function(){
                $(this).prop("checked", false);
            });
            // ラジオボタンの解除
            $(this).closest(".search-sort-list").find("input[type='radio']").each(function(){
                $(this).prop("checked", false);
            });
            // 価格のところの解除
            $(this).closest(".search-sort-list").find("input[type='text']").each(function(){
                $(this).val("");
            });

            $form.find("#product_search_submit").click();
        });
    });
});
