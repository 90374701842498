
export function init_order() {
  //注文キャンセル
  $(document).on('click', '.js-order-cancel', function () {
    const accept_order_id = $(this).attr('data-accept-order-id');
    const url = '/mypage/purchase_histories/order_cancel';
    $.ajax({
      type: 'POST',
      url: url,
      data: {
        accept_order_id: accept_order_id,
      },
      dataType: 'json'
    })
      .done(function (data) {
        $(`a[href='#modalOrderCancel${accept_order_id}']`).addClass('is-disabled');
        if(data.error) {
          modalClose();
          alert(data.error);
          return;
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        modalClose();
        alert('正しく反映されませんでした。画面を更新してください。');
      });
  });
}
