export function init_customers() {

  $(function(){
    const rhd = $('#customers_industry_cd').find("option:selected").data('rhd');
    display_attachment_file(rhd);

    // ----------------------------------------
    // 休診日・定休日（祝日）
    // ----------------------------------------
    $('.all_hol_flg_am, .all_hol_flg_pm').change(function() {
      const checked = $(this).prop('checked');
      const class_name = $(this).attr('class');

      $('.' + class_name).prop('checked', checked)
    });

    // ----------------------------------------
    // 業種選択による表示/非表示
    // ----------------------------------------
    $('#customers_industry_cd').change(function() {
      const selected = $(this).find('option:selected');
      const rhd = selected.data('rhd');

      // 必要書類表示
      display_attachment_file(rhd);
    });

    // ----------------------------------------
    // 必要書類表示
    // ----------------------------------------
    function display_attachment_file(rhd) {
      if(rhd == true) {
        $('#attachment_file').show();
        $('customers_attachment_file').prop('disabled', false);
      } else {
        $('#attachment_file').hide();
        $('customers_attachment_file').prop('disabled', true);
      }
    }

  });
}
