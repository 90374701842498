// 「在庫ありのみ表示」ボタンを押したときの挙動
$(function(){
    $("form#product_search").each(function(){
        $form =$(this);

        $(".search-control #select_sort").change(function(){
            value = $(this).val();
            $form.find("#product_search_sort").val(value);
            $form.find("#product_search_submit").click();
        });
    });
});
